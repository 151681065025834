<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center wi-list-table__row-cursor--pointer" @click="props.props.expanded = !props.props.expanded">
      {{props.item.id}}
    </td>
    <td class="justify-center wi-list-table__row-cursor--pointer" @click="props.props.expanded = !props.props.expanded">
      {{props.item.date_competence_format}}
    </td>
    <td class="justify-center wi-list-table__row-cursor--pointer" @click="props.props.expanded = !props.props.expanded">
      {{props.item.item_id}}
    </td>
    <td class="justify-center wi-list-table__row-cursor--pointer" @click="props.props.expanded = !props.props.expanded">
      <strong>{{props.item.item.name}}</strong>
    </td> 
    <td class="justify-center wi-list-table__row-cursor--pointer" @click="props.props.expanded = !props.props.expanded">
      <div v-if="props.item.type == 1" style="text-align: center;">
        <v-icon color="green">get_app</v-icon> <br>
        <span>Entrada</span>
      </div>
      <div v-if="props.item.type == 2" style="text-align: center;">
        <v-icon color="orange">publish</v-icon> <br>
        <span>Retirada</span>
      </div>
    </td>
    <td class="justify-center wi-list-table__row-cursor--pointer" @click="props.props.expanded = !props.props.expanded">
      <strong style="font-size: 18px;">{{props.item.quantity}}</strong>
    </td>
    <td class="justify-center wi-list-table__row-cursor--pointer" @click="props.props.expanded = !props.props.expanded">
      {{props.item.new_cost | money}}
    </td>
    <td class="justify-center wi-list-table__row-cursor--pointer" @click="props.props.expanded = !props.props.expanded">
      {{props.item.new_amount_sale | money}}
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'StockMovements',
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
.wi-list-table__row-cursor--pointer {
  cursor: pointer;
}
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
</style>